<template>
  <view-container name="info">
    <view-header>
      <custom-header :router-back="'/settings'">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft" />Info
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div class="info-menu">
        <ul>
          <li v-for="(route, index) in routes" :key="index">
            <router-link :to="route.url">
              <span>
                {{ route.title }}
              </span>
              <svg-icon
                size="size20x20"
                class-name="ms-auto"
                name="icoArrowRightBlue"
              />
            </router-link>
          </li>
        </ul>
      </div>
    </view-content>
  </view-container>
</template>

<script>
export default {
  name: 'Info',
  data() {
    return {
      routes: [
        {
          title: this.$t('pages.settings.terms'),
          url: '/settings/terms'
        },
        {
          title: this.$t('pages.settings.policy'),
          url: '/settings/policy'
        }
      ]
    }
  }
}
</script>